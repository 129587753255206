import { render, staticRenderFns } from "./QuickAddModal.vue?vue&type=template&id=688d5df6"
import script from "./QuickAddModal.vue?vue&type=script&lang=js"
export * from "./QuickAddModal.vue?vue&type=script&lang=js"
import style0 from "./QuickAddModal.vue?vue&type=style&index=0&id=688d5df6&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconArrowRight: require('/opt/build/repo/components/icons/IconArrowRight.vue').default,IconCloseGray: require('/opt/build/repo/components/icons/IconCloseGray.vue').default,ProductInfo: require('/opt/build/repo/components/product/ProductInfo.vue').default,ProductModifiers: require('/opt/build/repo/components/product/ProductModifiers.vue').default,ProductDetails: require('/opt/build/repo/components/product/ProductDetails.vue').default})
