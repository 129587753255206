
import { mapState, mapMutations } from "vuex";

export default {
  computed: {
    ...mapState(["errors", "appInfo"]),
    errorList() {
      if (this.errors) {
        if (Array.isArray(this.errors)) {
          return this.errors;
        } else if (typeof this.errors === "object") {
          return this.errors.messages;
        } else {
          return [this.errors];
        }
      }
      return [];
    },
    modalHeading() {
      if (this.errors?.heading === false) {
        return false;
      }

      return this.errors?.heading || "Error";
    },
  },
  mounted() {
    this.$nextTick(() => {
      document.addEventListener("keydown", this.trapFocus);
      this.$refs.errorModal.querySelectorAll('a, button')[0].focus();
    })
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.trapFocus);
  },
  methods: {
    ...mapMutations(["setErrors"]),
    trapFocus(event) {
      if (event.key === 'Escape') {
        this.close()
      }

      const errorModal = this.$refs.errorModal;
      const allFocusableElements = errorModal.querySelectorAll(
        'a[href]:not([disabled]), button:not([disabled]), input:not([disabled]), select:not([disabled]), [tabindex="0"]:not([disabled])'
      );
      const firstElement = allFocusableElements[0];
      const lastElement = allFocusableElements[allFocusableElements.length - 1];

      if (event.shiftKey && document.activeElement === firstElement) {
        lastElement.focus();
        event.preventDefault();
      }

      if (!event.shiftKey && document.activeElement === lastElement) {
        firstElement.focus();
        event.preventDefault();
      }
    },
    close() {
      this.setErrors([]);
    },
  },
};
