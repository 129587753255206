import { render, staticRenderFns } from "./Cart.vue?vue&type=template&id=1672ca76&scoped=true"
import script from "./Cart.vue?vue&type=script&lang=js"
export * from "./Cart.vue?vue&type=script&lang=js"
import style0 from "./Cart.vue?vue&type=style&index=0&id=1672ca76&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1672ca76",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Upsells: require('/opt/build/repo/components/globals/Upsells.vue').default,IconCloseGreen: require('/opt/build/repo/components/icons/IconCloseGreen.vue').default,IconCloseGray: require('/opt/build/repo/components/icons/IconCloseGray.vue').default,IconDirections: require('/opt/build/repo/components/icons/IconDirections.vue').default,HandoffModeSelector: require('/opt/build/repo/components/order/HandoffModeSelector.vue').default,OrderTimePicker: require('/opt/build/repo/components/order/OrderTimePicker.vue').default,DropdownDeliveryForm: require('/opt/build/repo/components/locations/DropdownDeliveryForm.vue').default,IconDoorDashFull: require('/opt/build/repo/components/icons/IconDoorDashFull.vue').default,NumberOfPeople: require('/opt/build/repo/components/order/NumberOfPeople.vue').default,IconChangeLocation: require('/opt/build/repo/components/icons/IconChangeLocation.vue').default,IconPlusCircle: require('/opt/build/repo/components/icons/IconPlusCircle.vue').default,CartItem: require('/opt/build/repo/components/globals/CartItem.vue').default,IconTrash: require('/opt/build/repo/components/icons/IconTrash.vue').default,LocationConfirmModal: require('/opt/build/repo/components/globals/LocationConfirmModal.vue').default})
