
  import { mapState, mapActions, mapMutations } from 'vuex'

  export default {
    props: {
      option: {
        type: Object,
        default: () => {}
      },
      showImages: {
        type: Boolean,
        default: false
      },
      isModal: {
        type: Boolean,
        default: false
      },
      singleRequiredMod: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        showedGlutenFreeMessage: false
      }
    },
    computed: {
      ...mapState(["location"]),
      ...mapState({
        choices: state => state.product.choices,
        product: state => state.product?.data,
        popups: state => state.app.globals.popups
      }),
      isFastFine() {
        return this.location?.c_fast_fine;
      },
    },
    mounted() {
      this.$nextTick(() => {
        this.preselectModifierIfDefault()
      })
    },
    methods: {
      ...mapMutations([ "setErrors" ]),
      ...mapActions({
        selectMod: 'product/selectMod',
        removeMod: 'product/removeMod',
      }),
      preselectModifierIfDefault() {
        if (this.option.default) {
          this.handleSelect(this.option)
          return // If we don't return here, we'll select the default option and then immediately deselect it if we selected below
        }

        // Client asked to preselect Regular Crust if it's the quick add m
        if (this.isModal) {
          if (this.option.name === 'Regular Crust') {
            this.handleSelect(this.option)
          }
        }
      },
      calories(option) {
        let base = option.nutrition?.calories?.base
        let max = option.nutrition?.calories?.max
        // if (base) {
        //   base = base.toString().replace('-','')
        // }
        // if (max) {
        //   max = max.toString().replace('-','')
        // }
        return (option.cost && base !== null  ? ' | ' : '') + (base !== null ? base : '') + (max !== null ? ' - '+max : '') + (base !== null ? ' Cal' : '')
      },
      isSelected(id) {
        return this.choices.find(choice => choice.id === id) !== undefined
      },
      async handleSelect(o) {
        if (this.isFastFine  && o.name.toLowerCase() === 'gluten-free crust' && !this.showedGlutenFreeMessage) {
          const glutenFreePopup = this.popups?.gluten_free_crust_popup[0]

          if (glutenFreePopup) {
            this.setErrors({
              heading: glutenFreePopup.heading,
              messages: [ glutenFreePopup.text ],
              buttonText: glutenFreePopup.button_text,
            });

            this.showedGlutenFreeMessage = true;
          }
        }

        if (this.isSelected(o.choice_id)) {
          const removingSize = this.product.mods.map(mod => {
            return mod.options.find(option => {
              return option.choice_id === o.choice_id
            })
          })[0]
          if (removingSize) {
            removingSize.mods.forEach(mod => {
              if (['Left','Right','Whole'].includes(mod.name)) {
                mod.options.forEach(option => {
                  option.mods.forEach(subMod => {
                    subMod.options.forEach(subOption => {
                      this.removeMod({ ...subOption, id: subOption.choice_id })
                    })
                  })
                })
              }
            })
          }
          this.removeMod({ ...o, id: o.choice_id })
        } else {
          this.selectMod({ ...o, id: o.choice_id, quantity: 1 })
        }
      },
    }
  }
