import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=08c28ada&scoped=true"
import script from "./Footer.vue?vue&type=script&lang=js"
export * from "./Footer.vue?vue&type=script&lang=js"
import style0 from "./Footer.vue?vue&type=style&index=0&id=08c28ada&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08c28ada",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconDropdown: require('/opt/build/repo/components/icons/IconDropdown.vue').default,IconFacebook: require('/opt/build/repo/components/icons/IconFacebook.vue').default,IconInstagram: require('/opt/build/repo/components/icons/IconInstagram.vue').default,IconTikTok: require('/opt/build/repo/components/icons/IconTikTok.vue').default,Footer: require('/opt/build/repo/components/globals/Footer.vue').default})
