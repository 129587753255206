import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=422f0af5&scoped=true"
import script from "./Header.vue?vue&type=script&lang=js"
export * from "./Header.vue?vue&type=script&lang=js"
import style0 from "./Header.vue?vue&type=style&index=0&id=422f0af5&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "422f0af5",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconMenu: require('/opt/build/repo/components/icons/IconMenu.vue').default,IconMenuClose: require('/opt/build/repo/components/icons/IconMenuClose.vue').default,LocationDropdown: require('/opt/build/repo/components/globals/LocationDropdown.vue').default,LocationDropdownColdState: require('/opt/build/repo/components/globals/LocationDropdownColdState.vue').default,IconBag: require('/opt/build/repo/components/icons/IconBag.vue').default,Header: require('/opt/build/repo/components/globals/Header.vue').default})
