
import { mapState, mapMutations } from "vuex";

export default {
  data() {
    return {
      selectedMethod: "pickup",
    };
  },
  computed: {
    ...mapState(["cart", "order", "location","channel"]),
    ...mapState({
      showLocationDropdown: (state) => state.app.showLocationDropdown,
      hidingLocationDropdown: (state) => state.app.hidingLocationDropdown,
    }),
    iconColor() {
      let color = "#BAF54D";
      if (this.$mq == "sm" || this.$mq == "md") {
        color = "#3D098E";
      }
      return color;
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
    document.addEventListener("keydown", this.trapFocus);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
    document.removeEventListener("keydown", this.trapFocus);
  },
  methods: {
    ...mapMutations({
      setShowLocationDropdown: "app/setShowLocationDropdown",
      setHidingLocationDropdown: "app/setHidingLocationDropdown",
    }),
    trapFocus(event) {
      if (event.key === "Escape" && this.showLocationDropdown) {
        this.setShowLocationDropdown(false);
      }

      const dropdownContent = this.$refs.dropdownContent;

      if (!dropdownContent || !this.showLocationDropdown) {
        return;
      }

      const allFocusableElements = dropdownContent.querySelectorAll(
        'a[href]:not([disabled]), button:not([disabled]), input:not([disabled]), select:not([disabled]), [tabindex="0"]:not([disabled])'
      );

      const focusableElements = Array.from(allFocusableElements).filter(el => {
        const style = window.getComputedStyle(el);
        return style.display !== 'none' && style.visibility !== 'hidden';
      });

      if (focusableElements.length === 0) return;
      const firstElement = focusableElements[0];
      const lastElement = focusableElements[focusableElements.length - 1];

      if (event.shiftKey && document.activeElement === firstElement) {
        lastElement.focus();
        event.preventDefault();
      }

      if (!event.shiftKey && document.activeElement === lastElement) {
        firstElement.focus();
        event.preventDefault();
      }
    },
    selectPickup() {
      this.selectedMethod = "pickup";
      this.setShowLocationDropdown(false);
      this.runMethod()
    },
    selectDelivery() {
      this.selectedMethod = "delivery";
      this.setShowLocationDropdown(true);
      this.runMethod()
    },
    runMethod() {
      if (this.selectedMethod === "pickup") {
        this.$router.push("/locations");
      }
      // else {
      //   this.setShowLocationDropdown(!this.showLocationDropdown);
      // }
    },
    handleClickOutside(event) {
      if (
        this.showLocationDropdown &&
        !this.$el.contains(event.target) &&
        event.target !== this.$el
      ) {
        this.setShowLocationDropdown(false);
      }
    },
    handleKeyDown(event) {
      if (event.key === "Escape" && this.showLocationDropdown) {
        this.setShowLocationDropdown(false);
      }
    },
    goToCatering() {
      this.$router.push('/catering')
    }
  },
};
