import { render, staticRenderFns } from "./IconGlutenFree.vue?vue&type=template&id=d1cca250"
import script from "./IconGlutenFree.vue?vue&type=script&lang=js"
export * from "./IconGlutenFree.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports